@use "../config/"as *;



.login {
    @extend %c-white-10;

    .main-wrap {
        @extend %p-t-zero;
    }
}
.waf-component{
    padding: 0;
}

.waf-user-section {
    @extend %w-100;

    &::after {
        content: "";
        z-index: var(--zindex-overlay);
        background: url(/static-assets/images/cssimages/login-bg.jpg?v=#{$image-version}) no-repeat;
        background-size: cover;
        background-attachment: fixed;
        @include position(0, 0, 0, 0, fixed);
        @extend %c-black-bg-3;
        @extend %d-block;
    }
}

.waf-user-section {
    @extend %p-x-half;
    @extend %m-zero;

    .head {
        @extend %flex-sb-c;
        @extend %m-b-two-space;
    }

    .head-logo {
        width: 4rem;
        @extend %ratio-1-1;
    }

    .back-btn {
        .btn-text {
            @extend %font-14-pr;
            @extend %c-white-10;

            &:before {
                @include icon('\e800');
            }
        }
    }

    .form-body-wrapper {
        backdrop-filter: blur(0.17rem);
        margin: var(--full-space) auto;
        padding: var(--two-space) var(--full-space);
        @include linear-gradient(104.13deg, hsla(var(--hsl-c-pure-white)/0.24) -16.79%, hsla(var(--hsl-c-pure-white)/ 0.06) 38.23%, hsla(var(--hsl-c-pure-white)/ 0.06) 67.48%, hsla(var(--hsl-c-pure-white)/ 0.24) 116.93%);
        @include border(1, c-white, 2);
        @extend %full-radius;
    }

    .custom-form {
        @extend %text-center;

        .btn-register {
            @extend %w-100;
            @extend %m-b-full;
        }

        .sub-title {
            @extend %font-14-pr;
        }

    }

    .btn {

        &-facebook,
        &-gmail,
        &-apple {
            @extend %c-blue-bg-10;
            @extend %flex-c-c;
            @extend %m-b-full;
            @extend %w-100;

            &:before {
                width: 2.4rem;
            }

            .btn-text {
                @extend %font-16-pr;
            }
        }

        &-facebook {
            &:before {
                content: url(/static-assets/images/cssimages/svg/facebook.svg?v=#{$image-version});
            }
        }

        &-gmail {
            &:before {
                content: url(/static-assets/images/cssimages/svg/Google.svg?v=#{$image-version});
            }
        }

        &-apple {
            &:before {
                content: url(/static-assets/images/cssimages/svg/apple.svg?v=#{$image-version});
                opacity: 0.7;
            }
        }

        &-text {
            @extend %c-white-10;
            @extend %font-16-pb;
            @extend %m-l-half;
        }

        &-register {
            @extend %m-y-full;
            @extend .btn-fill;

            .btn-text {
                @extend %c-blue-10;
            }
        }

        &-site {
            .btn-text {
                @extend %font-14-pb;
                @extend %c-orange-10;
            }
        }

        &-pwd-toggle {
            .password-sign {
                &:after {
                    @extend %c-white-10;
                }
            }

        }
    }

    .note {
        margin: var(--full-space-negative) 0 var(--two-space);
        @extend %flex-column-fs-fs;
        @extend %p-l-full;

        .text {
            @extend %font-12-pr;
        }
    }

    .form {
        &-title {
            @extend %uppercase;
            @extend %font-24-sb;
        }

        &-desc {
            @extend %text-center;
            @extend %font-14-pr;

        }

        &-container {
            @extend %m-t-two-space;
        }

        &-element {
            position: relative;
            @extend %m-b-two-space;
        }

        &-group {
            &.password-field {
                position: relative;

                input {
                    @extend %p-r-two-space;
                }
            }
        }

        &-step {
            @include font(16, null, $font-pr);
            @include color(c-orange, 10);
            @extend %m-y-full;
        }

        &-footer {
            @extend %m-y-full;

            .btn-link {
                width: max-content;
                cursor: pointer;
                @extend %m-auto;
                @extend %flex-c-c;
                @extend %font-16-pb;
            }
        }
    }

    .verification {
        .control-label {
            @extend %text-center;
            @extend %c-white-10;
            @extend %font-18-pr;
            @extend %m-y-two-space;
        }
    }

    .no-data-wrap {
        .title {
            box-shadow: none;
            @extend %bg-transparent;
        }
    }



    //athletes 
    .athletes-group {
        .search-field {
            position: relative;

            &:after {
                @include icon('\e815');
                @include position-combo(tr);
            }

            input {
                @extend %font-14-pr;
            }
        }

        .card {
            &-list {
                @include grid(repeat(2, 1fr));
            }

            &-item {
                position: relative;
                @include border-radius(var(--half-radius));

                .country-img {
                    width: 2rem;
                    height: 2rem;
                    @extend %ratio-1-1;
                    @extend %circle-radius;
                }

                .checkbox {
                    opacity: 0;
                    z-index: 24;
                    cursor: pointer;
                    @extend %w-100;
                    @extend %h-100;
                    @include position(0,null,null,0);
                    // @include position-combo(inset);

                    &:checked {
                        ~.card-wrapper {
                            .select-option {
                                &:after {
                                    @extend %c-orange-10;
                                    content: url(/static-assets/images/svg/star-icon.svg?v=#{$image-version});
                                }
                            }

                            .card-thumbnail {
                                @include bg(c-blue-light, 4);
                            }

                        }
                    }

                }
            }

            &-thumbnail {
                height: calc(100% - 5rem);
                @include bg(c-blue-light, 2);
                @include border-radius(var(--half-radius) var(--half-radius) 0 0);
            }

            &-select {
                .select-option {
                    width: 2.2rem;
                    height: 2.2rem;
                    @extend %ratio-1-1;
                    @extend %circle-radius;
                    @extend %c-white-bg-4;
                    @extend %flex-c-c;

                    &:after {
                        content: url(/static-assets/images/svg/star-icon-outline.svg?v=#{$image-version});
                        font: 1.8rem/1 $font-icon;
                        @extend %c-blue-6;
                    }
                }

            }

            &-badge {
                padding: calc(var(--half-space)/2) var(--half-space);
                background-color: hsla(var(--hsl-c-black)/0.4);
                font: 1.2rem/1.8 $font-pr;
                @include position(calc(var(--quarter-space) + var(--half-space)), var(--half-space));
                @extend %badges;
                @extend %flex-n-c;
            }

            &-content {
                height: 5rem;
                @include border-radius(0 0 var(--half-radius) var(--half-radius));
                @extend %c-blue-bg-7;
                @extend %flex-sb-c;
                @extend %p-half;
            }

            &-name {
                width: calc(100% - 2rem);
                @extend %text-left;
                @extend %flex-column;

                .fname {
                    font: 1.2rem/1.2 $font-pr;
                    @extend %c-white-7;
                }

                .lname {
                    font: 1.4rem/1.2 $font-pr;
                }
            }
            &-wrapper{
                @extend %h-100;
            }
        }

        .label-group-list {
            flex-wrap: wrap;
        }

        .tab {
            &-name {
                border-color: var(--c-white);
                margin: 0 var(--quarter-space) var(--half-space);
            }

            &-text {
                @extend %c-white-10;
            }
        }
    }

    //styles list
    .styles-group {
        .card {
            &-list {
                @include grid(repeat(2, 1fr));

                input {
                    opacity: 0;
                    z-index: var(--zindex-input);
                    cursor: pointer;
                    @extend %w-100;
                    @extend %h-100;
                    @include position(0,null,null,0);
                    // @include position-combo(inset);
                }
            }

            &-item {
                @extend %ratio-1-1;
                @extend %half-radius;

                &:before {
                    content: '';
                    z-index: var(--zindex1);
                    pointer-events: none;
                    opacity: .3;
                    @include position-combo(inset);
                    @include border-radius(var(--half-radius));
                    @include linear-gradient(0deg, hsl(var(--hsl-c-blue-2)/.7) 0%, hsl(var(--hsl-c-blue-2)/.7) 100%);
                    // @include linear-gradient(162deg, hsl(var(--hsl-c-blue)/1), hsl(var(--hsl-c-white)/0.3));
                }
                &:hover {
                    &::before {
                        opacity: .5;
                    }
                }
            }

            &-content {
                background-color: transparent;
                z-index: var(--zindex1);
                @extend %position-center;
                @extend %w-100;
                @extend %flex-c-c;
            }

            &-title {
                @extend %font-14-pb;
                @extend %c-pure-white-10;
            }

            &-wrapper {
                position: relative;
                @extend %w-100;
                @extend %h-100;

                &:before {
                    z-index: var(--zindex-star);
                    content: url(/static-assets/images/svg/star-icon-outline.svg?v=#{$image-version});
                    @include position(var(--full-space), var(--full-space));
                }

            }

            &-thumbnail {
                background-color: transparent;
                @extend %h-100;

                img {
                    @include border-radius(var(--half-radius), hidden);
                }
            }
        }

        .checkbox {
            &:checked {
                ~.card-wrapper {
                    &:before {
                        content: url(/static-assets/images/svg/star-icon.svg?v=#{$image-version});
                        @include position(var(--full-space), var(--full-space));
                    }

                    &:after {
                        content: '';
                        z-index: var(--zindex-pattern);
                        background: linear-gradient(350deg, var(--c-white), var(--c-blue)) padding-box,
                            linear-gradient(120deg, var(--c-white), var(--c-blue)) border-box;
                        border: 0.1rem solid transparent;
                        @include border-radius(var(--half-radius), hidden);
                        @include position-combo(center);
                        @extend %ratio-1-1;
                        @extend %w-100;
                        @extend %h-100;
                    }
                }
            }
        }
    }

    //tournament
    .tournament-group {

        @each $event,
        $event-key in $events {
            @include fav-event($event, $event-key);
        }

        .card {
            &-list {
                @include grid(repeat(2, 1fr));
            }

            &-item {
                background-color: var(--c-blue);
                height: 22rem;
                @extend %h-100;

                &::after {
                    content: '';
                    @extend %c-blue-bg-10;
                }
            }

            &-thumbnail {
                width: 9rem;
                z-index: var(--overlay-image);
                margin: var(--two-space) auto var(--full-space);
                background: transparent;
                @extend %ratio-1-1;
            }

            &-content {
                background-color: transparent;
            }

            &-title {
                z-index: var(--zindex1);
                line-height: 2rem;
                @extend %font-16-pb;
                @extend %text-center;
                @extend %m-auto;
            }

            &-wrapper {
                &:before {
                    content: url(/static-assets/images/svg/star-icon-outline.svg?v=#{$image-version});
                    z-index: var(--zindex-star);
                    @include position(var(--full-space), var(--full-space));
                }
            }
        }

        .checkbox {
            opacity: 0;
            z-index: 24;
            cursor: pointer;
            @extend %w-100;
            @extend %h-100;
            @include position(0,null,null,0);
            // @include position-combo(inset);

            &:checked {
                ~.card-wrapper {
                    &:before {
                        content: url(/static-assets/images/svg/star-icon.svg?v=#{$image-version});
                        @include position(var(--full-space), var(--full-space));
                    }

                }
            }

        }

    }

    //team
    .teams-group {
        .card {
            &-list {
                @include grid(repeat(4, 1fr));

                input {
                    opacity: 0;
                    z-index: var(--zindex-input);
                    cursor: pointer;
                    @extend %w-100;
                    @extend %h-100;
                    @include position(0,null,null,0);
                    // @include position-combo(inset);
                    &:checked {
                        ~.card-wrapper {
                            position: relative;
                            border: 0.2rem solid transparent;
                            background-image: linear-gradient(hsl(var(--hsl-c-blue)/0.3), var(--c-blue)),
                            linear-gradient(340deg, hsl(var(--hsl-c-white)), var(--c-blue),var(--c-white), hsl(var(--hsl-c-blue)/0.3));
                            background-origin: border-box;
                            background-clip: padding-box,
                            border-box;
                            border-radius: 50%;
                        }
                    }
                }

            }

            &-item {
                img {
                    flex-shrink: 0;
                    @extend %h-100;
                    @extend %circle-radius;
                }
                &:hover {
                    .card-wrapper {
                        @include border(10, c-blue-1, 8);
                    }
                }
            }

            &-thumbnail {
                width: 5rem;
                height: 5rem;
                @extend %ratio-1-1;
                @extend %flex-column;

                .text {
                    @extend %m-y-full;
                    @extend %d-block;
                    @extend %font-14-pr;
                    @extend %c-white-5;
                    @extend %uppercase;
                }

            }
            &-wrapper{
                width: 7rem;
                height: 7rem;
                @include border-radius(50%);
                @extend %ratio-1-1;
                @extend %flex-c-c;
                // @include border(10, c-white, 1);
                @include border(10, c-blue-1, 3);
            }
        }

        .pagination {
            margin-block: calc(5 * var(--full-space));
        }

        .search-field {
            input {
                &:focus {
                    ~.btn-close {
                        .btn-text {
                            &::after {
                                @include icon('\e804');
                            }
                        }
                    }
                }
            }

            .btn {
                &-text {
                    @extend %flex-column-c-c;
                    @extend %font-zero;
                    @extend %c-white-10;
                }

                &-close {
                    @include position(null, 0, var(--full-space));
                    @include translate(0, -50%);

                    .btn-text {
                        &:after {
                            @include icon('\e815');
                        }
                    }
                }
            }
        }
    }

    .fav-athletes {
        .card-thumbnail {
            .card-img {
                // object-fit: contain;
                @include border-radius(var(--half-radius) var(--half-radius) 0 0,hidden);
            }
        }
    }

    .filter-section {
        .filter-label-group {
            width: calc(100% - 20rem);
        }

        .filter-hide-data {
            width: 20rem;
        }
    }
    .filter{
        &-result-list {
            flex-wrap: wrap;
            @extend %w-100;
            @extend %flex;
            @extend %m-y-three-space;
            &:empty{
                @extend %m-zero;
            }
        }

        &-result-data {
            @extend %w-100;
        }

        &-result-item {
            @extend %flex;

            img {
                position: relative;
                width: 6rem;
                height: 6rem;
                @include border-radius(50%, hidden);
                @extend %ratio-1-1;
                @extend %c-white-bg-3;
            }

            label {
                background: hsl(var(--hsl-c-blue)/1);
                color: var(--c-white);
                padding-right: 3rem;
                @extend %p-half;
                @extend %badges;

                &+.btn-close {
                    top: 0.25rem;
                    right: 2.5rem;
                    background-color: transparent;

                    .btn-text {
                        &::before {
                            @extend %c-white-4;
                        }
                    }

                }
                span{
                    @extend %c-orange-10;
                    @extend %font-12-pr;
                    @extend %m-l-half;
                }
            }

            .btn-close {
                width: 2rem;
                height: 2rem;
                @include border-radius(50%, visible);
                @include position(0, 2.2rem, null, null, relative);
                @extend %c-white-bg-10;

                .btn-text {
                    @extend %w-100;
                    @extend %h-100;
                    @extend %flex-c-c;
                    @extend %m-zero;
                    @extend %font-zero;
                    @extend %d-block;

                    &::before {
                        @include icon('\e804');
                        @extend %c-black-10;
                    }
                }
            }
        }
    }
}


@media screen and (min-width:$tablet-min-width) {
    .waf-user-section {
        .back-btn {
            .btn-text {
                @include font(18);
                @include flex-config(flex, null, null, center);

                &::before {
                    @include font(32);
                }
            }
        }

        .note {
            padding-left: 0;
            @include flex-config(flex, row);

            .text {
                margin-inline: var(--full-space);
                @include font(12);
            }
        }

        .form-body-wrapper {
            max-width: 62rem;
            margin: var(--three-space) auto var(--full-space);
            padding: calc(5 * var(--full-space));

            .form {
                &-container {
                    flex-wrap: wrap;
                    @include flex-config(flex,null,center);
                }

                &-footer {
                    width: 80%;
                    margin-inline: auto;
                    @include flex-config(flex, column, null, center);

                    .btn-register {
                        width: 100%;
                    }
                }

                &-title {
                    @include font(32);
                }
            }

            .social-list {
                width: 100%;
                @include flex-config(flex, column);
            }

            .form-register-user,
            .form-new-user,
            .form-existing-user {
                width: 100%;
            }

            .fname-field {
                margin-right: var(--half-space);
            }

            &.fav-athletes,
            &.fav-styles,
            &.fav-teams,
            &.fav-tournament {
                max-width: 102rem;
                padding: var(--three-space) calc(4 * var(--full-space));

                .form-footer {
                    margin-inline: auto;
                    @include flex-config(flex, row, center);

                    .btn-link {
                        width: 40%;
                        margin: unset;
                    }

                    .btn-register {
                        width: 30%;
                    }
                }
            }
            &.fav-tournament{
                max-width: 102rem;
                padding-inline: calc(4 * var(--three-space));
            }

        }

        .athletes-group {
            .card {
                &-list {
                    width: 100%;
                    grid-auto-flow: row;
                    @include grid(repeat(4, 1fr));
                }
                &-item{
                    height: 22rem;
                }
            }

        }

        .fav-tournament {
            .card {
                &-list {
                    width: 100%;
                    @include grid(repeat(3,1fr),var(--full-space));
                }

                &-title {
                    width: 60%;
                }
                &-item{
                    width: 19rem;
                }
            }

            .form-footer {
                margin: var(--three-space) auto 0;
            }
        }

        .teams-group {
            .card {
                &-list {
                    @include grid(repeat(10, 1fr));
                }

                &-item {
                    width: 7rem;
                    height: 7rem;
                    overflow: unset;
                }

                &-thumbnail {
                    width: 5rem;
                    height: 5rem;
                }
            }
        }

        .form-container {
            &.styles-group {
                display: block;

                .card {
                    &-list {
                        @include grid(repeat(4, 1fr));
                    }
                    &-item{
                        width: 19rem;
                    }
                }
            }
        }
        .head-logo{
            width: 7.1rem;
            height: 9.1rem;
        }

    }
}